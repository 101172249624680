import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { Container } from "reactstrap";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
import resume from "./resume.pdf"

export default function Resume () {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
  
    function onDocumentLoadSuccess({ numPages }) {
      setNumPages(numPages);
    }
  
    // return (
    //   <Container className="d-flex w-100">
    //     <Document
    //       file= {resume}
    //       onLoadSuccess={onDocumentLoadSuccess}
    //       onLoadError={console.error}
    //     >
    //       <Page pageNumber={pageNumber} />
    //     </Document>
    // </Container>
    // );

    return (
        <embed src={resume} className="w-100" height="2100px" />
    );
}