import React from "react";

import { Card, CardBody, Col, Button, CardText } from "reactstrap";

import { Fade } from "react-reveal";

const ProjectsCard = ({ data }) => {
	return (
		<Col xs={12}>
			<Fade bottom duration={1000} distance="40px">
				<Card className="shadow-lg--hover shadow mt-4">
					<CardBody>
						<div className="d-flex px-3">
							<div className="pl-4">
								<h3>{data.name}</h3>
								{data.vimeoLink ? <div className="text-center embed-responsive embed-responsive-16by9">
                <iframe title="vimeo-player" src={data.vimeoLink} width="640" height="354" frameBorder="0" allowFullScreen></iframe></div> : null}
								{data.image ? <img src={data.image} className="img-fluid"/> : null}
								<p className="description mt-3">{data.desc}</p>

								<CardText className="description my-3 text-left">
								<ul>
									{data.descBullets
										? data.descBullets.map((desc) => {
												return <li className="description mt-3" key={desc}>{desc}</li>;
										})
										: null}
								</ul>
								</CardText>
								{data.github ? (
									<Button
										className="btn-icon"
										color="github"
										href={data.link}
										target="_blank"
									>
										<span className="btn-inner--icon">
											<i className="fa fa-github" />
										</span>
									</Button>
								) : null}
								{data.link ? (
									<Button
										className="btn-icon"
										color="success"
										href={data.link}
										target="_blank"
									>
										<span className="btn-inner--icon">
											<i className="fa fa-arrow-right mr-2" />
										</span>
										<span className="nav-link-inner--text ml-1">
											{data.linkLabel}
										</span>
									</Button>
								) : null}
							</div>
						</div>
					</CardBody>
				</Card>
			</Fade>
		</Col>
	);
};

export default ProjectsCard;
