import emoji from "react-easy-emoji";

import duolingologo from "./assets/img/icons/common/duolingo-icon.png";
import fidelitylogo from "./assets/img/icons/common/fidelity-icon2.jpeg";
import squarecaplogo from "./assets/img/icons/common/squarecap-icon.png";

import stockular from "./assets/img/stockular.png";
import researchgame from "./assets/img/research-game.png";
import iqualify from "./assets/img/iqualify.png";

export const greetings = {
	name: "Kevin Li",
	title: "Hello, I'm Kevin",
	description:
		"I'm currently a CS + Math student at the University of Texas at Austin. I enjoy full stack development and building applications from the ground up. I'm also particularly interested in financial markets and emerging technologies, like cryptocurrency and the blockchain.",
	resumeLink: "/resume",
};

export const openSource = {
	githubUserName: "kxl4126",
};

export const contact = {};

export const socialLinks = {
	// facebook: "https://www.facebook.com/1Kevin100",
	// instagram: "https://www.instagram.com/1Kevin100",
	// twitter: "https://twitter.com/1Kevin100",
	github: "https://github.com/kxl4126",
	linkedin: "https://www.linkedin.com/in/kevin-li-09a567193/",
	mail: "mailto:kevinl@cs.utexas.edu",
};

export const skillsSection = {
	title: "Skills",
	subTitle:
		"I have experience in the below technologies and am always learning more!",
	skills: [
		emoji(
			"⚡ Python, Java, C++, Solidity, React, Node, Express, Kotlin, AWS, PostgreSQL, GraphQL, Redis"
		),
	],

	softwareSkills: [
		{
			skillName: "JavaScript",
			fontAwesomeClassname: "logos:javascript",
		},
		{
			skillName: "TypeScript",
			fontAwesomeClassname: "logos:typescript-icon",
		},
		{
			skillName: "reactjs",
			fontAwesomeClassname: "vscode-icons:file-type-reactjs",
		},
		{
			skillName: "nodejs",
			fontAwesomeClassname: "logos:nodejs-icon",
		},
		{
			skillName: "sql-database",
			fontAwesomeClassname: "vscode-icons:file-type-sql",
		},
		{
			skillName: "aws",
			fontAwesomeClassname: "logos:aws",
		},
		{
			skillName: "firebase",
			fontAwesomeClassname: "logos:firebase",
		},
		{
			skillName: "python",
			fontAwesomeClassname: "logos:python",
		},
		{
			skillName: "git",
			fontAwesomeClassname: "logos:git-icon",
		},
		{
			skillName: "docker",
			fontAwesomeClassname: "logos:docker-icon",
		},
		{
			skillName: "Java",
			fontAwesomeClassname: "logos:java",
		},
		{
			skillName: "Solidity",
			fontAwesomeClassname: "logos:ethereum",
		},
		{
			skillName: "GCP",
			fontAwesomeClassname: "logos:google",
		},
	],
};

export const SkillBars = [
	{
		Stack: "Frontend/Design", //Insert stack or technology you have experience in
		progressPercentage: "90", //Insert relative proficiency in percentage
	},
	{
		Stack: "Backend",
		progressPercentage: "70",
	},
	{
		Stack: "Programming",
		progressPercentage: "60",
	},
];

export const educationInfo = [
	{
		schoolName: "University of Texas at Austin",
		subHeader: "Bachelor's of Science in Computer Science",
		duration: "May 2019 - May 2023",
		desc: "Focused on Software Engineering, Full Stack development, and AI.",
		descBullets: [
			"GPA - 4.0",
			"Coursework - Data Structures (Java), Computer Architecture (C), Artificial Intelligence, Operating Systems (C++), Algorithms, Software Engineering, Modern Web Apps, Neural Networks"
		],
	},
	{
		schoolName: "University of Texas at Austin",
		subHeader: "Bachelor's of Science in Mathematics",
		duration: "May 2019 - May 2023",
		desc: "Focused on Statistics, Financial Modeling, and other practical applications.",
		descBullets: [
			"GPA - 4.0",
			"Coursework - Discrete Math, Calculus, Linear Algebra, Statistics and Data Science, Financial Mathematics, Applied Statistics"
		],
	},
];

export const experience = [
	{
		role: "Software Engineer Intern",
		company: "Duolingo",
		companylogo: duolingologo,
		date: "May 2021 – August 2021",
		desc: "Focus on backend development, API design, and running experiments",
		descBullets: [
			"Revamped subscriptions management backend to support more subscription types using Kotlin, Webflux, and Python",
			"Migrated 4 million subscriptions from AWS DynamoDB to RDS based subscriptions backend microservice",
			"Designed and implemented various production and internal APIs used to create and manage subscriptions",
			"Created algorithmically generated Duolingo NFT characters using Solidity and React.js as a hackathon project",
			"Increased revenue and user retention by conducting backend A/B testing experiments"	
		],
	},
	{
		role: "Software Engineer Intern",
		company: "Fidelity Investments",
		companylogo: fidelitylogo,
		date: "June 2020 – August 2020",
		desc: "Focus on data analytics, machine learning, and backend development",
		descBullets: [
			"Built topic models, such as LDA and STTM, to categorize, identify, and score customer feedback regarding different Fidelity applications",
			"Optimized sentiment models for the specific contexts of the different applications",
			"Created web application with appealing interface to make it easier for the product team to browse the data and NLP model results",
			"Pipelined and processed data using Node, Express, and SQL"

		]
	},
	{
		role: "Software Engineer Intern",
		company: "Squarecap",
		companylogo: squarecaplogo,
		date: "Jan 2020 – May 2020",
		desc: "Focus on fullstack development and web scraping.",
		descBullets: [
			"Devised and debug Python scripts used to scrape the internet for marketing leads",
			"Developed features to encourage student attendance and participation in class with React.js",
			"Processed and filter student answer requests with Node.js",
			"Managed server side payment details with Pyramid Framework, Python and Braintree API"

		],
	},
];

export const projects = [
	{
		name: "Stockular",
		desc: "A web app for social sentiment/trends tracking for financial markets.",
		descBullets: [
			"Scaled website to support 20k monthly users and tens of thousands of comments/posts processed daily",
			"Programmed scrapers to parse Reddit discussion data and exchange data using Python, Google Cloud Functions and Cloud Scheduler",
			"Designed intuitive website with appealing data visualizations of collected data using React.js",
			"Architected system of parallel processing to scrape raw data, extract trends, and cleanup database simultaneously on Google Cloud",
			"Performed data analysis and machine learning on long term, granular data to develop a profitable trading strategy"
		],
		link: "https://stockular.app",
		linkLabel: "Link to Site",
		vimeoLink: "https://player.vimeo.com/video/541996788"

	},
	{
		name: "IQualify",
		desc: "Website to help demystify COVID relief and make it simpler to access your benefits",
		descBullets: [
			"HackTX 2nd place Google Cloud Competition",							 
			"HackTX Google Cloud - COVID-19 Hackathon Fund",
			"Designed an easy-to-navigate UI with React.js for users to efficiently calculate and access COVID benefits information",
			"Supported data analysis and visualization of anonymous form submission data through Google Charts and Google Maps API with Google Cloud",
			],
		link: "https://iqualify.space",
		linkLabel: "Link to Site",
		image: iqualify

	},
	{
		name: "HRI Robotics Study",
		desc: "Investigated the relationship between synthetic voices and decision making",
		descBullets:[
			"Developed immersive game with HTML/Javascript engaging players in a decision-based story game",
			"Conducted statistical analysis and various tests to determine significance and applicability of final results"
		],
		github: "https://github.com/kxl4126/VoiceFRI",
		link: "https://drive.google.com/file/d/1R1Hbht7oxx84Vfa6B-sk8H6EsJqhNOBc/view",
		linkLabel: "Link to Paper",
		image: researchgame
	},
	{
		name: "Terminal AI RL Agents",
		desc: "Experimented with various Deep Reinforcement Learning techniques within the Terminal AI environment using Python",
		descBullets: [
			"Developed automated testing/training framework to simulate self play similar to Google’s AlphaGo",
			"Tuned model based on simulated feedback to eventually achieve consistent victory against certain NPCs",
		],
		github: "https://github.com/kxl4126/TerminalAISeason7",
	},
];

export const feedbacks = [
	{
		name: "Hassan Mehmood",
		feedback:
			"We have been very happy with our new website! It looks professional and very easy to navigate. Our experience with the customer service at Kevin Li has been great. They handle things very efficiently and are available for any questions we have. They also keep us updated on daily reports so we know how our site is doing. I would recommend that u have choose Kevin web developer services for u.",
	},
	{
		name: "Maheen Altaf",
		feedback:
			"the website is very nice, everything was perfectly made as i need. it just loads in moments, without giving u the chance to wait. the quality is also very amazing. i am using it without any problem. great job",
	},
];
