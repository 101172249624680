import React from 'react';

import {
    Card,
    Col,
    Row,
    Container,
} from "reactstrap";

import SocialLinks from "../components/SocialLinks";

import profilepic from "../assets/img/profilepic.jpg"



const GithubProfileCard = ({prof}) => {
    return ( 
            <Card className="section-lg bg-gradient-info shadow-lg border-0">
                <Container className="">
                  <Row className="">
                  {/* <Col>
                      <img src={prof.avatar_url} style={{width: "200px"}} alt="" className="rounded-circle img-center img-fluid shadow shadow-lg--hover mb-4"/>
                    </Col> */}
                    <Col className="text-center">
                      <h2 className="text-white">
                        Contact
                      </h2>
                      <p className="lead text-white mt-3">
                        Want to connect?
                      </p>
                      <p className="text-white mt-3">
                      </p>
                        <SocialLinks />
                    </Col>                    
                  </Row>
                </Container>
              </Card>
     );
}
 
export default GithubProfileCard;