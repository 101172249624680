import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Home from './Home';
import Resume from "./Resume";

// add basic routing for resume, /resume route for resume, home route for everything else
export const App = () => {
 return (
    <Router>
      <Switch>
        <Route exact path="/" name="Home">
          <Home/>
        </Route>
        <Route exact path="/resume" name="Resume">
          <Resume/>
        </Route>
      </Switch>
    </Router>
 )
}