import React from "react";
import ReactDOM from "react-dom";


import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/font-awesome/css/font-awesome.min.css";
import "assets/css/argon-design-system-react.css";
import { App } from "App";

ReactDOM.render(
		<App/>,
	document.getElementById("root")
);
